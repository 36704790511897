import React from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';

import Layout from '../Layout';
import useStoryblok from '../../../../lib/storyblok';
import Notification from '../../../v1/molecules/notification';
import { Section } from '../Section';
import Bloks from '../../../atoms/Bloks';
import { Location } from '../../../../types';

type Props = {
  location: Location;
  pageContext: any;
  data: any;
};

const LandingPageTemplate: React.FC<Props> = ({ location, pageContext, data }) => {
  let story = pageContext.story;
  story = useStoryblok(story, location);

  let HeaderAndFooterData = useStoryblok(data.headerAndFooter, location);
  const { content } = HeaderAndFooterData;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={story.content.SEO} />
      <Section curved noGrid hero curveColor="tertiary">
        <Bloks bloks={story.content.header} className="col-span-4 lg:col-span-6" />
      </Section>
      <Bloks bloks={story.content.section} story={story} />
      <Notification isVisible={queryString.parse(location.search).formSubmit === 'success'} />
    </Layout>
  );
};

export default LandingPageTemplate;

export const query = graphql`
  query landingPagesV2Template {
    headerAndFooter: storyblokEntry(full_slug: { eq: "blog/" }) {
      content
    }
  }
`;
